<template>
  <div class="page-gray" style="padding-top: 1px;">

    <div class="orderInfoCard">
      <div class="top van-hairline--bottom">
        <div class="item">
          <div class="key" >退款状态</div>
          <div class="value">{{arr[afterSalesOrder.orderStatus]}}</div>
        </div>
        <div class="item">
          <div class="key">申请时间:</div>
          <div class="value">{{ afterSalesOrder.createTime }}</div>
        </div>
        <div class="item">
          <div class="key">申请人:</div>
          <div class="value">{{ afterSalesOrder.createBy }}</div>
        </div>
        <div class="item">
          <div class="key">申请类型:</div>
          <div class="value">{{ afterSalesOrder.userapply?'用户申请':'后台申请' }}</div>
        </div>
        <div class="item">
          <div class="key">退款编号:</div>
          <div class="value gray">{{ afterSalesOrder.orderSn }}</div>
        </div>
        <div class="item">
          <div class="key">退款类型:</div>
          <div class="value">{{ afterSalesOrder.returnflag == 0?'线上退款':'线下退款' }}</div>
        </div>
      </div>
    </div>

    <div class="orderInfoCard">
      <div class="top van-hairline--bottom">
        <div class="item">
          <div class="key">退款原因:</div>
          <div class="value">{{ afterSalesOrder.memo }}</div>
        </div>
        <div class="item" v-if="afterSalesOrder.rejectReason">
          <div class="key">驳回理由:</div>
          <div class="value">{{ afterSalesOrder.rejectReason }}</div>
        </div>
      </div>
    </div>

    <div class="orderCard">
      <div class="state" style="color: #333">商品信息</div>
      <div class="goodslist" v-for="goods in afterSalesOrder.orderReturnItemVos">
        <div class="goodsItem">
          <div class="goodsInfo">
            <van-image
                :src="goods.productImg"
            />
            <div class="right">
              <div class="top">
                <div class="name van-multi-ellipsis--l2">
                  <div>{{ goods.name }}</div>
                </div>
                <div class="price">
                  <span>¥</span>
                  <span>{{ goods.price }}</span>
                </div>
              </div>
              <div class="sku">数量：{{ goods.quantity }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="orderInfoCard">
      <div class="top van-hairline--bottom">
        <div class="item">
          <div class="key">退款金额:</div>
          <div class="value gray">￥{{ (afterSalesOrder.totalPrice+afterSalesOrder.postFee).toFixed(2) }} （含邮费￥{{afterSalesOrder.postFee}}）</div>
        </div>
        <div class="item">
          <div class="key">退款时间:</div>
          <div class="value gray">{{ afterSalesOrder.refundTime }}</div>
        </div>

      </div>
    </div>

    <div class="placeholder"></div>
    <div class="bottomHandle">
      <van-button size="mini"
                  v-if=" afterSalesOrder.orderStatus==0"
                  type="info" plain round color="#212121"
                  @click="cancel">撤销申请</van-button>
    </div>

  </div>
</template>

<script>
import {mapState} from 'vuex'
export default {
  name: "refundDetail",
  components: {
  },
  data() {
    return {
      stepsA: ['提交申请', '厂家处理', '寄回商品', '厂家退款', '退款成功'],
      stepsB: ['提交申请', '厂家审核 ', '商品寄回', '退款审核',"退款成功"],
      arr:["已申请","已确认","已完成","已取消","已驳回"],
      afterSalesOrder: {},
      fileList: [],
      postageOrder:{},
      editShow:false,
      logs:[],
      typeList:["仅退款","退货退款","换货"],
      orderStatusList:["未确认","已确认","已完成","已取消"],
    }
  },
  computed:{
    ...mapState(['afterSalesSet']),
  },
  methods: {
    init(refundId) {
      this.$http.get(`/mall/h5_mall/queryReturnDetails/${refundId}`).then(res => {
        this.afterSalesOrder = res.data;
      });
    },
    cancel() {
      this.$dialog.confirm({
        title: '提示',
        message: `您将撤销本次申请，如果问题未解决，如果问题未解决，您还可以再次发起,确定撤销吗？ 撤销后，若超出订单服务保障期，将无法再次发起退款`,
      }).then(() => {
        // TODO SEVER
        this.$http.post(this.baseMallUrl + `/h5_mall_order/revokeApplication/${this.afterSalesOrder.id}`,
            {}, {
              emulateJSON: true
            }).then(() => {
          this.$toast.success({
            message:'撤销成功',
            onClose:()=>{
              this.init(this.afterSalesOrder.id);
            },
          })
        })
      }).catch(() => {
        // on cancel
      });
    },
  },
  mounted() {
    let refundId = this.$route.params.id;
    this.init(refundId);
  },
};
</script>
<style lang="less" scoped>

.orderCard {
  background: #FFFFFF;
  border-radius: 8px;
  margin: 10px;

  .state {
    height: 34px;
    font-size: 13px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    line-height: 34px;
    padding: 0 19px;
    border-bottom: 1px solid #eee;
  }

  .goodslist {

    .goodsItem {
      padding: 16px 18px 21px;

      & + .goodsItem {
        padding-top: 0;
      }

      .goodsInfo {
        display: flex;
        align-items: flex-start;
      }

      .van-image {
        width: 110px;
        min-width: 110px;
        height: 92px;
        margin-right: 13px;
      }

      .right {
        flex: 1;
        width: 0;

        .top {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          .name {
            width: 155px;
            font-size: 12px;
            color: #333333;
            line-height: 15px;

          }

          .price {
            line-height: 15px;
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            font-weight: bold;
            color: #D53A26;

            span:last-child {
              font-size: 15px;
            }
          }
        }

        .sku {
          background: #eeeeee;
          border-radius: 2px;

          padding: 5px 10px;
          line-height: 10px;
          font-size: 10px;
          color: #666666;

          display: inline-block;
          margin: 5px 0 4px;
        }

        .tagList {
          margin: 6px 0 0;
          font-size: 0;

          .tag {
            display: inline-block;
            background: #ffffff;
            border: 1px solid #ff0000;
            border-radius: 3px;
            line-height: 10px;
            font-size: 10px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ff0000;

            padding: 2px 5px;
          }
        }

        .tips {
          line-height: 10px;
          font-size: 10px;
          color: #e7ac58;
        }

        .plain {
          width: 68px;
          height: 23px;
          border: 1px solid #999999;
          border-radius: 112px;

          font-size: 11px;
          color: #212121;
          display: flex;
          align-items: center;
          justify-content: center;
          float: right;
          margin-top: 6px;

          &.active {
            color: #ff0000;
            border-color: #ff0000;
          }

          & + .plain {
            margin-right: 10px;
          }
        }
      }

    }
  }
}

.orderInfoCard {
  background: #FFFFFF;
  border-radius: 8px;
  margin: 10px;

  .top {
    padding: 10px 0;
  }

  .item {
    padding: 6px 18px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .key {
      font-size: 12px;
      color: #333;
      width: 80px;
      min-width: 80px;

      span {
        color: #999;
        font-size: 10px;
      }
    }

    .value {
      font-size: 12px;
      color: #333;
      font-weight: 400;

      &.price {
        color: #d53a26;
      }

      &.gray {
        font-weight: 400;
        color: #999;
      }
    }
  }
}

.placeholder {
  height: 65px;
}

.bottomHandle {
  height: 53px;
  position: fixed;
  bottom: 0;
  width: 100vw;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 11px;


  .van-button {
    padding: 0;
    width: 68px;
    font-size: 11px;
  }

  .van-button--plain.van-button--info {
    border-color: #999 !important;
  }

  .van-button--mini + .van-button--mini {
    margin-left: 7px;
  }
}


.arrow {
  height: 13px;
  width: 8px;
  min-width: 8px;
}
</style>
